<template>
  <div>
    <v-card class=" mt-3 ml-2 mr-2">
      <v-card-subtitle>
        <span class="blue--text text-h6"> Kullanıcı Ekle</span>
      </v-card-subtitle>
      <v-divider />
      <v-card-text>
        <div class="col-md-12">
          <v-btn class="ma-2" outlined color="indigo" dense @click="createFarm">
            <v-icon>mdi-plus</v-icon>

            Şirket Ekle
          </v-btn>
        </div>
        <div class="col-md-12">
          <v-data-table
            :headers="headers"
            :items="desserts"
            :items-per-page="itemPerPage"
            class="elevation-1"
            dense
          >
            <template v-slot:item.companyCode="{ item }">
              <v-icon
                small
                class="mr-2"
                dense
                outlined
                @click="editItemOpen(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon small dense outlined @click="deleteItem(item)">
                mdi-delete
              </v-icon>

              <!--   <v-btn
                color="blue darken-1"
                text
                dense
                 
                outlined
                @click="sahaAdd(item)"
              >
                Saha
              </v-btn> -->
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="blue--text"> <h2>Şirket Ekle</h2></span>
        </v-card-title>
        <v-card-text>
          <v-col cols="row">
            <!--Şirket Adı:-->
            <v-text-field
              ref="companyName"
              v-model="company.companyName"
              label="Şirket Adı:"
              class="col-9 mb-0 mt-0 pt-0 pb-0"
              outlined
              dense
              hidden-footer
              hidden-details
              hidden-hint
              required
            />

            <!--Telefon:-->
            <v-text-field
              ref="adress"
              v-model="company.Phone"
              label="Telefon"
              class="col-9 mb-0 mt-0 pt-0 pb-0"
              outlined
              dense
              hidden-footer
              hidden-details
              hidden-hint
              required
            />

            <!--Adres:-->
            <v-text-field
              ref="adress"
              v-model="company.adress"
              label="Adres"
              class="col-9 mb-0 mt-0 pt-0 pb-0"
              outlined
              dense
              hidden-footer
              hidden-details
              hidden-hint
              required
            />

            <!--Durum-->
            <v-select
              v-model="company.status"
              label="Durum"
              dense
              class="col-9 ml-0 mb-0 mt-0 pt-0 pb-0"
              attach=""
              outlined
              :items="['Active', 'Passive']"
            />
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            dense
            outlined
            @click="dialog = false"
          >
            Kapat
          </v-btn>
          <v-btn
            v-if="farmEdit === 'false'"
            color="blue darken-1"
            text
            dense
            outlined
            @click="setfunc"
          >
            Kayıt
          </v-btn>

          <v-btn
            v-if="farmEdit === 'true'"
            color="blue darken-1"
            text
            dense
            outlined
            @click="editFarm"
          >
            Güncelle
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  setCompanyCreate,
  setCompanyEdit,
  getCompanyList,
  getCompanyDel,
} from "@/api/Request/company";

export default {
  data: () => ({
    company: {
      companyName: "",
      adress: "",
      status: "",
    },
    farmEdit: "false",
    dialog: false,
    itemPerPage: 10,
    desserts: [],
    headers: [],
  }),
  created() {},
  mounted() {
    this.getCompany();
  },
  methods: {
    getCompany() {
      const params = {};
      this.headers = [
        { text: "Şiket Adı", value: "companyName" },
        { text: "Adres", value: "adress" },
        { text: "Durum", value: "status" },
        { text: "Tarih", value: "createTime" },
        { text: "", value: "companyCode" },
      ];

      getCompanyList(params).then((res) => {
        this.desserts = res.data.data;
      });
    },
    deleteItem(item) {
      const params = {
        condiniton: {
          companyCode: item.companyCode,
        },
      };
      getCompanyDel(params).then((res) => {
        // console.log(res);
        this.getCompany();
      });
    },

    setfunc() {
      const params = { data: this.company };
      setCompanyCreate(params).then((res) => {
        // console.log(res);
        this.getCompany();
      });
    },
    createFarm() {
      this.company = {
        companyName: "",
        adress: "",
        status: "",
      };
      this.dialog = true;
      this.farmEdit = "false";
    },
    editItemOpen(item) {
      this.company = item;
      this.farmEdit = "true";
      this.dialog = true;
    },
    sahaAdd(item) {
      this.$router.push(`/farm/lists/${item.companyCode}`);
    },
    editFarm() {
      const params = {
        condiniton: {
          companyCode: this.company.companyCode,
        },
        data: this.company,
      };

      setCompanyEdit(params).then((res) => {
        // console.log(res);
        this.getCompany();
      });
    },
  },
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
</style>
